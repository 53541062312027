








































































































import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import config from '@/env';
import { SsrCtx, WithAsyncData, WithMeta } from '@/core/vue.types';
import { AppStore } from '@/themes/v1/stores/app.store';
import LayoutHeaderExtends from '@/themes/v1/layouts/LayoutHeaderExtends.vue';
import SearchContainer from '@/themes/v1/containers/Search.vue';
import Box from '@/themes/v1/components/Box/Box.vue';
import Pagination from '@/themes/v1/components/Pagination.vue';
import AskBox from '@/themes/v1/components/AskBox.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import SectionCircleLink from '@/themes/v1/components/SectionCircleLink.vue';
import Sticky from '@themes/v1/components/Sticky.vue';
import { SearchService } from '@/modules/guide/search/search.service';
import { MatchedArticle } from '@/modules/guide/search/search-result.model';
import LayoutShadow from '@/themes/v1/layouts/LayoutShadow.vue';
import TwoColumn from '@/themes/v1/components/TwoColumn.vue';
import { SystemStore } from '@/core/system';
import { resolveFromCtx } from '@/themes/v1/fns/inject-resolver';
import Spinner from '@themes/v1/components/Spinner.vue';
import Adaptive from '@/themes/v1/components/Adaptive';
import NeedSomethingElse from '@/themes/v1/components/NeedSomethingElse.vue';
import ButtonPagination from '@/themes/v1/components/ButtonPagination.vue';
import { MetaInfo } from 'vue-meta';
import Multiline from '@/themes/v1/directives/multiline-text';
import QuestionMark from '@/themes/v1/components/QuestionMark.vue';

const MOBILE_TEXT_LIMIT = 40;

@Component({
  components: {
    ButtonPagination,
    NeedSomethingElse,
    Adaptive,
    TwoColumn,
    LayoutShadow,
    Sticky,
    BoxContent,
    AskBox,
    Pagination,
    Box,
    LayoutHeaderExtends,
    SearchContainer,
    SectionCircleLink,
    Spinner,
    QuestionMark,
  },
  directives: {
    Multiline,
  },
})
export default class Search extends Vue implements WithAsyncData, WithMeta {
  @Inject() searchService!: SearchService;

  items: MatchedArticle[] = [];
  page = 1;
  total = 0;
  appStore = getModule(AppStore, this.$store);
  systemStore = getModule(SystemStore, this.$store);
  loading = false;

  async asyncData(ctx: SsrCtx) {
    const page = ctx.route.query.page
      ? parseInt(ctx.route.query.page as string)
      : 1;
    const searchService = resolveFromCtx<SearchService>('searchService', ctx);
    const { items, total } = await searchService.search(
      config.game,
      ctx.app.$i18n.locale,
      ctx.route.query.query as string,
      (page - 1) * 10,
      10
    );
    return {
      page,
      items,
      total,
    };
  }

  get sections() {
    const sections = ['guide', 'faq']
      .map((key) => this.appStore.sections[key])
      .filter(Boolean)
      .map((x) => ({
        key: x.pathSegment,
        path: this.$routerHelper.withPlatformAndLang(
          this.$route,
          x.pathSegment
        ),
        name: x.name,
      }));
    return sections;
  }

  @Watch('$route.query')
  queryParamChanged(routeQuery) {
    if (routeQuery.page) {
      this.page = parseInt(routeQuery.page);
    } else {
      this.page = 1;
    }
    this.load();
  }

  private async load() {
    this.loading = true;
    const { items, total } = await this.searchService.search(
      config.game,
      this.$i18n.locale,
      this.$route.query.query as string,
      (this.page - 1) * 10,
      10
    );
    this.loading = false;
    this.items = items;
    this.total = total;
  }

  get pagerCount() {
    return this.systemStore.device.isMobileOrTablet ? 4 : 6;
  }

  get queryText() {
    let text = `${this.$route.query.query}`;
    if (this.systemStore.device.isMobile && text.length > MOBILE_TEXT_LIMIT) {
      text = `${text.substr(0, MOBILE_TEXT_LIMIT).trim()}...`;
    }
    return text;
  }

  metaInfo(): MetaInfo {
    return {
      title: this.$t('meta.search.title') as string,
    };
  }
}
