




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Sticky extends Vue {
  get offset() {
    return JSON.stringify({ top: this.$layout().ingame ? 30 : 80 });
  }
}
