<template>
  <Box theme="secondary" class="text--center ask-box" :opacity="70">
    <BoxContent space="small">
      <h3
        class="m-b--lv3 ask-box__title"
        v-html="$t('components.needHelp.question')"
      ></h3>
      <div class="ask-box__btn-wrapper">
        <router-link
          data-id="ask-question-button"
          :to="$routerHelper.withPlatformAndLang($route, 'tickets')"
          @click.native="
            $track('ask_question', 'click_button_ask_question', $route.fullPath)
          "
          class="button button--large ask-box__btn"
        >
          {{ $t('components.needHelp.title') }}
        </router-link>
      </div>
    </BoxContent>
  </Box>
</template>

<script>
import Box from './Box';
import BoxContent from '@themes/v1/components/Box/BoxContent';
export default {
  name: 'AskBox',
  components: { BoxContent, Box },
};
</script>

<style scoped></style>
