var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pagination"},[_c('div',{staticClass:"pagination__pager"},[_c('button',{class:[
        'pagination__button pagination--back',
        _vm.current === 1 && 'pagination__button--disabled' ],on:{"click":function($event){return _vm.$emit('change', _vm.current - 1)}}},[_vm._v(" "+_vm._s(_vm.$t('phrases.back'))+" ")]),(_vm.pageCount > 0)?_c('button',{class:[
        'pagination__button pagination--item',
        _vm.current === 1 && 'pagination__button--active' ],on:{"click":function($event){return _vm.$emit('change', 1)}}},[_vm._v(" 1 ")]):_vm._e(),(_vm.showPrevMore)?_c('div',{staticClass:"pagination__splitter"},[_vm._v("...")]):_vm._e(),_vm._l((_vm.pagers()),function(pager){return _c('button',{key:pager,class:[
        'pagination__button pagination--item',
        pager === _vm.current && 'pagination__button--active' ],on:{"click":function($event){return _vm.$emit('change', pager)}}},[_vm._v(" "+_vm._s(pager)+" ")])}),(_vm.showNextMore)?_c('div',{staticClass:"pagination__splitter"},[_vm._v("...")]):_vm._e(),(_vm.pageCount > 1)?_c('button',{class:[
        'pagination__button pagination--item',
        _vm.pageCount === _vm.current && 'pagination__button--active' ],on:{"click":function($event){return _vm.$emit('change', _vm.pageCount)}}},[_vm._v(" "+_vm._s(_vm.pageCount)+" ")]):_vm._e(),_c('button',{class:[
        'pagination__button pagination--forward',
        _vm.current === _vm.pageCount && 'pagination__button--disabled' ],on:{"click":function($event){return _vm.$emit('change', _vm.current + 1)}}},[_vm._v(" "+_vm._s(_vm.$t('phrases.forward'))+" ")])],2),_c('div',{staticClass:"pagination__showing"},[_vm._v(" "+_vm._s(_vm.$t('components.pagination.showing', { from: (this.current - 1) * this.pageSize + 1, to: this.ofCount, total: this.total, }))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }