






























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ButtonPagination extends Vue {
  @Prop({ type: Number, required: true })
  total!: number;

  @Prop({ type: Number, required: true })
  pageSize!: number;

  @Prop({ type: Number, required: true })
  currentPage!: number;

  get pageCount() {
    return Math.ceil(this.total / this.pageSize);
  }

  get current() {
    return this.currentPage > this.pageCount
      ? this.pageCount
      : this.currentPage;
  }

  get ofCount() {
    const val = this.current * this.pageSize;
    return val > this.total ? this.total : val;
  }
}
