









import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TwoColumn extends Vue {
  @Prop({
    default: '',
  })
  auxClass!: string;

  get hasRightSlot() {
    return this.$slots['right'];
  }
}
