



































































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Pagination extends Vue {
  @Prop({ type: Number, required: true })
  total!: number;

  @Prop({ type: Number, required: true })
  pageSize!: number;

  @Prop({ type: Number, required: true })
  currentPage!: number;

  @Prop({ type: Number, default: 6 })
  pagerCount!: number;

  showPrevMore = false;
  showNextMore = false;

  get pageCount(): number {
    return Math.ceil(this.total / this.pageSize);
  }
  get current(): number {
    return this.currentPage > this.pageCount
      ? this.pageCount
      : this.currentPage;
  }

  get ofCount(): number {
    const val = this.current * this.pageSize;
    return val > this.total ? this.total : val;
  }

  pagers(): number[] {
    const pagerCount = this.pagerCount;
    const halfPagerCount = (pagerCount - 1) / 2;
    const currentPage = Number(this.currentPage);
    const pageCount = Number(this.pageCount);
    let showPrevMore = false;
    let showNextMore = false;
    if (pageCount > pagerCount) {
      if (currentPage > pagerCount - Math.floor(halfPagerCount)) {
        showPrevMore = true;
      }
      if (currentPage < pageCount - Math.round(halfPagerCount)) {
        showNextMore = true;
      }
    }
    const array: number[] = [];
    if (showPrevMore && !showNextMore) {
      const startPage = pageCount - (pagerCount - 2);
      for (let i = startPage; i < pageCount; i++) {
        array.push(i);
      }
    } else if (!showPrevMore && showNextMore) {
      for (let i = 2; i < pagerCount; i++) {
        array.push(i);
      }
    } else if (showPrevMore && showNextMore) {
      const offset = Math.floor(pagerCount / 2) - 1;
      for (let i = currentPage - offset; i <= currentPage + offset; i++) {
        array.push(i);
      }
    } else {
      for (let i = 2; i < pageCount; i++) {
        array.push(i);
      }
    }
    this.showPrevMore = showPrevMore;
    this.showNextMore = showNextMore;
    return array;
  }
}
